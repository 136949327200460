import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Start from './Pages/start-meeting-hooks';
function App() {
  return (
    <Router>
           

                <Switch>
                    <Route exact path="/:accessCode">
                        <Start />
                    </Route>
                    
                   
                </Switch>
             
        </Router>
  );
}

export default App;
