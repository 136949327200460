import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
const theme = createMuiTheme({
 
  palette: {
    primary: {
      main: "#5c6bc0",
    },
    secondary: {
      main:"#ffaa00",
    } ,
    info:{
      main:"#fff"
    },
    error:{
      main:"#e53e3e"
    }
  },
});
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      < App />

    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
