import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@material-ui/core';
import Logo from '../assets/logo.png'
import PresentToAll from '@material-ui/icons/PresentToAll';
import VideoOff from '@material-ui/icons/VideocamOff';
import MicOff from '@material-ui/icons/MicOff';
import MicOn from '@material-ui/icons/Mic';
import VideoOn from '@material-ui/icons/Videocam';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AgoraRTC from 'agora-rtc-sdk-ng';
import CallEndIcon from '@material-ui/icons/CallEnd';
import * as firebase from 'firebase';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Lodash from 'lodash';
import { AccountCircleRounded, ScreenShare } from '@material-ui/icons';
import { Fragment } from 'react';
import { green } from '@material-ui/core/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';

var client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });
var localTracks = {
    videoTrack: null,
    audioTrack: null,

};
var options = {
    appid: '2782bd1ec5a9459f8585b53cdae23988',
    channel: '',
    uid: null,
    token: null
};
var config = {
    apiKey: "AIzaSyBCtNKTr4AFNe-k1dtOG_cmQl0833zdKmE",
    authDomain: "sentio-meeting-production.firebaseapp.com",
    databaseURL: "https://sentio-meeting-production.firebaseio.com",
    projectId: "sentio-meeting-production",
    storageBucket: "sentio-meeting-production.appspot.com",
    messagingSenderId: "258888910278",
    appId: "1:258888910278:web:40c5c43873bd3a7c4fdec7",
    measurementId: "G-NG01GQ1Z0C"
};
const axiosInstance = axios.create({
    baseURL: 'https://data.sentiovr.com/api/v1',

    headers: getHeaders()
});
function getHeaders() {
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'api-key': '7nZOLvhjP21/XqzuQCb0uylmBnbAtcPMil+6momlp5E='

    }
}
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

 

 
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    player: {
        height: "500px",
        width: "500px"
    },
    textSecondary: {
        color: "#fff"
    },
    off: {
        background: "#D93025"
    },
    on: {
        background: "trasnparent"
    },
    buttonProgress: {
        color: green[500],
        position: 'relative',
        top: '-37px',

    },
    name: { position: "absolute", bottom: 0, margin: "0px", padding: "16px", color: "#fff", zIndex: 101, backgroundColor: '#00000080' },
    videoThumbnail: { height: "100%", width: "100%" },
    presentationWindow: { height: "Calc(100vh - 64px)", width: "Calc(20%)" },
    darkBG: { backgroundColor: "#000000e6" },
    alert: {
        minHeight: "0px",
        position: "absolute",
        right: "8px",
        top: "8px",

    },
    cameraPlaceholderBG: {
        position: 'absolute',
        top: '0px',
        background: '#191919',
        borderBottom: ' 1px solid #e2e3e4',
    }
}));


export default function StartMeeting() {

    const classes = useStyles();
    let history = useHistory();
    let { accessCode } = useParams();
    let query = useQuery();
    const [streaming, setStreaming] = useState(true);
    const [name, setName] = useState(query.get("name") ? query.get("name") : "");
    const [password, setPassword] = useState("");
    const [audio, setAudio] = useState(true);
    const [video, setVideo] = useState(true);
    const [isScreenShared, setIsScreenShared] = useState(false);
    const [screenTrack, setScreenTrack] = useState();
    const [isScreenSharedLocal, setIsScreenSharedLocal] = useState(false);
    const [meetingURL, setMeetingURL] = useState("");
    const [meetingId, setMeetingId] = useState("");
    const [attendeeIndex, setAttendeeIndex] = useState("");
    const [remoteUsers, setRemoteUsers] = useState([]);
    const [attendeesFromFbase, setAttendeesFromFbase] = useState();
    const [meetingStarted, setMeetingStarted] = useState(false);
    const [errorOpen, setOpen] = React.useState(false);
    const [hostJoined, setHostJoined] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [screenTrackID, setScreenTrackID] = React.useState();
    const [step, setStep] = React.useState(1);
    const [leaveConfirmDialogOpen, setLeaveConfirmDialogOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("We are unable to complete the request, please try again later");
    var height = 0;
    options.channel = accessCode;
    var userNameList = [];
    const matches = !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    var width = matches ? 500 : 310;    // We will scale the photo width to this
    var videoElement = matches ? document.getElementById('video') : document.getElementById('video-mobile');;
    window.addEventListener("orientationchange", function (event) {

        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then(function (stream) {
                videoElement.srcObject = stream;
                videoElement.style.cssText = "-moz-transform: scale(-1, 1); \
    -webkit-transform: scale(-1, 1); -o-transform: scale(-1, 1); \
    transform: scale(-1, 1); filter: FlipH;"
                videoElement.play().then(() => {


                    height = videoElement.videoHeight / (videoElement.videoWidth / width);

                    videoElement.setAttribute('width', width);
                    videoElement.setAttribute('height', height);


                })
            }).catch(function (err) {
                console.log("An error occurred: " + err);
            });
        // console.log("the orientation of the device is now " + event.target.screen.orientation.angle);
    });
    React.useEffect(() => {
        remoteUsers.map((user) => {
            if (user.videoTrack) {
                user.videoTrack.play(`player-${user.uid}`);

                if (user.uid === screenTrackID) {
                    setIsScreenShared(true);

                }
            }
            if (user.audioTrack)

                user.audioTrack.play();

        })
        setUserNamesOfAttendees();

        console.log(userNameList);
    }, [remoteUsers]);
    useEffect(() => {
        if (matches) {
            videoElement = document.getElementById('video');

        }
        else {
            videoElement = document.getElementById('video-mobile');

        }
        ToggleCamera();
    }, [streaming]);

    useEffect(() => {
        if (isScreenShared && screenTrackID !== options.uid) {
            var screenSharedUser = remoteUsers.filter((user) => {
                return user.uid === screenTrackID
            })[0]
            screenSharedUser.videoTrack.play(document.getElementById('screen-share'));

        }

        console.log("sreens shared");
    }, [isScreenShared]);
    useEffect(() => {
        if (!screenTrackID) {
            setIsScreenShared(false);
        }
    }, [screenTrackID]);

    useEffect(() => {
        if (meetingStarted && meetingId) {
            attachRealtimeDBEventHandlers();
        }
    }, [meetingStarted, meetingId]);
    useEffect(() => {
        if (meetingStarted && meetingURL ) {
            setIFrame();
        }
    }, [meetingStarted,meetingURL]);

    React.useEffect(() => {
        if (video)
            startVideo();
        else
            stopVideo();
    }, [video]);
    React.useEffect(() => {
        if (audio)
            unMuteAudio();
        else
            muteAudio();
    }, [audio]);

    React.useEffect(() => {
        if (hostJoined && !password) {
            setTimeout(() => { checkMeetingStarted(); }, 1000)

        }
    }, [hostJoined]);

    React.useEffect(() => {
        if (screenTrack) {
            setIsScreenSharedLocal(true);
            client.unpublish(localTracks.videoTrack).then(() => {
                localTracks.videoTrack = screenTrack;
                localTracks.videoTrack.on("track-ended", handleScreenSharingEnded)
                client.publish(localTracks.videoTrack);
            })
        }



    }, [screenTrack]);


    React.useEffect(() => {
        if (attendeesFromFbase) {
            setRemoteUsers(() => Array.from(client.remoteUsers));
        }



    }, [attendeesFromFbase]);
    const attachWindowEvent = () => {
        window.addEventListener('beforeunload', function (event) {

            if (password) {
                var dbRef = firebase.database().ref('space_' + accessCode);
                dbRef.update({
                    active_meeting_id: "",

                });
            }
            leave();


        })
    }
    React.useEffect(() => {
        attachWindowEvent();


    }, [])
    React.useEffect(() => {
        if (attendeeIndex !== "") {
            var dbRefMeeting = firebase.database().ref('space_' + accessCode + "/" + meetingId + "/attendees/" + attendeeIndex);
            dbRefMeeting.onDisconnect().remove();
        }



    }, [attendeeIndex])


    // Set the configuration for your app
    // TODO: Replace with your project's config object
 
   
 
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
    }
    const setUserNamesOfAttendees = () => {
        var dbRef = firebase.database().ref('space_' + accessCode + "/" + meetingId + "/attendees");
        console.log(remoteUsers)

        dbRef.once('value').then((snapshot) => {
            Lodash.forIn(snapshot.val(), function (value, key) {
                if ((document.getElementById(value.uid) && screenTrackID === value.uid)) {
                    document.getElementById(value.uid).innerText = value.name + " is presenting";
                }
                else if (document.getElementById(value.uid)) {
                    document.getElementById(value.uid).innerText = value.name;

                }
            });
        });
    }

    const verifyPassCode = () => {
        axiosInstance.get('/user/createMeeting?access_code=' + accessCode + '&passcode=' + password)
            .then(function (response) {
                // handle success
                console.log(response);
                getMeetingURL().then((resp) => {
                    console.log(resp.data);
                    setMeetingURL(resp.data.meeting_url)

                    join();
                    setMeetingStarted(true)
                    setIsLoading(false);

                })

            })
            .catch(function () {
                // handle error
                setOpen(true);
                setIsLoading(false);

                setErrorMessage("Incorrect meeting passcode");
            })
            .then(function () {
                // always executed
            });
    }
    const getMeetingURL = () => {
        return axiosInstance.get('/space/meetingUrl/' + accessCode)
    }

    const checkMeetingStarted = () => {
        setIsLoading(true);
        var dbRef = firebase.database().ref('space_' + accessCode + "/active_meeting_id");
        dbRef.once('value').then(function (snapshot) {
            console.log(snapshot.val())
            if (snapshot.val()) {
                getMeetingURL().then((response) => {
                    console.log(response.data);
                    setIsLoading(false);
                    setMeetingURL(response.data.meeting_url)
                    join();
                    setMeetingStarted(true)
                }).catch(function () {
                    // handle error
                    setOpen(true);
                    setIsLoading(false);
                    setErrorMessage("Could not get space URL, hence unable to join");
                })

            }
            else {
                // alert("Please wait for the host to start the meeting")
                setMeetingStarted(false)
                setStep(2);
                var dbRefMeetingStarted = firebase.database().ref('space_' + accessCode + "/active_meeting_id");
                dbRefMeetingStarted.on('value', (snapshot) => {
                    console.log("-------------------------------------------------------------------");
                    console.log(snapshot.val());
                    if (snapshot.val()) {
                        setHostJoined(true);

                    }
                    console.log("-------------------------------------------------------------------");


                })
            }
        });

    }

    const attachRealtimeDBEventHandlers = () => {

        var dbRefScreenShare = firebase.database().ref('space_' + accessCode + "/" + meetingId + "/active_screen_trackID");
        dbRefScreenShare.on('value', (snapshot) => {
            setScreenTrackID(snapshot.val());

        })

        var dbRefMettingID = firebase.database().ref('space_' + accessCode + "/active_meeting_id");
        dbRefMettingID.on('value', (snapshot) => {
            if (snapshot.val() === "") {
                leave();
            }

        })
        var dbRef = firebase.database().ref('space_' + accessCode + "/" + meetingId + "/attendees");
        dbRef.on('value', (snapshot) => {
            setAttendeesFromFbase(Lodash.values(snapshot.val()))
            // setAttendeeIndex(Lodash.keys(Lodash.filter(snapshot.val(), (value, key) => {
            //     return (value.uid === options.uid)
            // }))[0])
        })
        dbRef.on('child_added', function (data) {
            if (data.val().uid === options.uid) {
                setAttendeeIndex(data.key)
            }
        });




    }
    // Get a reference to the database service
    function writeUserData(userId, name) {

        if (password) {
            var dbRef = firebase.database().ref('space_' + accessCode + '/');

            var meetingId = "meeting_" + Date.now();
            setMeetingId(meetingId);
            var data = {
                [meetingId]: {
                    attendees: [
                        { name: name, uid: userId }
                    ],
                    Click_action: {},
                    cur_scene: {},
                    mouse_position: {},
                    view_points: {}
                }

            }
            dbRef.update(data).then((data) => {

                console.log(data)
            });

            dbRef.update({
                active_meeting_id: meetingId,


            });
            firebase.database().ref('space_' + accessCode + '/active_meeting_id').onDisconnect().set("");


        }
        else {
            var dbRef = firebase.database().ref('space_' + accessCode + "/active_meeting_id");
            dbRef.once('value').then(function (snapshot) {
                setMeetingId(snapshot.val());

                firebase.database().ref('space_' + accessCode + "/" + snapshot.val() + "/attendees").push().set({ name: name, uid: userId }).then((data) => {
                    console.log(data);
                    //   setUserNamesOfAttendees();
                })

            });

        }



    }
    const TurnCameraOff = () => {

        setStreaming(false);
        setVideo(false);

    }

    const muteAudio = () => {
        if (localTracks.audioTrack)
            localTracks.audioTrack.setEnabled(false);
    }
    const unMuteAudio = () => {
        if (localTracks.audioTrack)

            localTracks.audioTrack.setEnabled(true);
    }
    const stopVideo = () => {


        if (localTracks.videoTrack)

            localTracks.videoTrack.setEnabled(false)
    }
    const startVideo = () => {
        if (localTracks.videoTrack)

            localTracks.videoTrack.setEnabled(true)
    }

    async function join() {
        setOpen(false);
        history.push(accessCode + "?name=" + name);
        // create Agora client
        client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });

        // add event listener to play remote tracks when remote user publishs.
        client.on("user-published", handleUserPublished);
        client.on("user-unpublished", handleUserUnpublished);

        // join a channel and create local tracks, we can use Promise.all to run them concurrently
        [options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
            // join the channel
            client.join(options.appid, options.channel, options.token || null),
            // create local tracks, using microphone and camera
            AgoraRTC.createMicrophoneAudioTrack(),
            AgoraRTC.createCameraVideoTrack(),
            // AgoraRTC.createScreenVideoTrack()
        ]);

        // play local video track
        localTracks.videoTrack.play("local-player");
        if (!video) {
            stopVideo();
        }
        if (!audio) {
            muteAudio();
        }
        // document.getElementById(("local-player-name").text(`localVideo(${options.uid})`);
        writeUserData(options.uid, name);
        await client.publish(Object.values(localTracks))

        console.log("publish success");
        // publish local tracks to channel



    };
    async function subscribe(user, mediaType) {
        // subscribe to a remote user
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        setRemoteUsers(() => Array.from(client.remoteUsers));

    }

    const handleUserPublished = (user, mediaType) => {

        subscribe(user, mediaType);


    }
    function updateFirebaseAttendeesList() {
        var dbRefMeeting = firebase.database().ref('space_' + accessCode + "/" + meetingId);
        var attendees = Lodash.filter(attendeesFromFbase, function (value, key) {
            return value.uid !== options.uid
        });
        return dbRefMeeting.update({
            attendees: attendees,

        });

    }
    function leave() {
        setLeaveConfirmDialogOpen(false);
        updateFirebaseAttendeesList().then(() => {
            if (password) {
                setHostJoined(false);
                var dbRef = firebase.database().ref('space_' + accessCode);
                dbRef.update({
                    active_meeting_id: "",

                });
            }

            if (localTracks.audioTrack) {
                localTracks.audioTrack.stop()
                localTracks.audioTrack.close()
            }
            if (localTracks.videoTrack) {
                localTracks.videoTrack.stop()
                localTracks.videoTrack.close()
            }
            setMeetingStarted(false);
            window.location.reload(true);
            client.leave();

        })



    }
    const returnDialog = () => {
        return (
            <Dialog
                maxWidth='xs'
                open={leaveConfirmDialogOpen}
                onClose={() => {
                    setLeaveConfirmDialogOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">{"Leave meeting?"}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText >
                        Are you sure you want to leave the meeting ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>

                    <Button onClick={leave} style={{ color: "#000", border: "1px solid #e2e3e4" }} autoFocus>
                        Yes
                        </Button>
                    <Button onClick={() => {
                        setLeaveConfirmDialogOpen(false);
                    }} style={{ background: "#ffaa00", color: "#fff" }} >
                        No
                        </Button>

                </DialogActions>
            </Dialog>
        )
    }






    const handleUserUnpublished = (removedUser) => {
        const users = Array.from(client.remoteUsers).filter((user) => {
            return user.uid !== removedUser.uid
        })
        setRemoteUsers(() => users);



    }



    const TurnCameraOn = () => {
        setStreaming(true);
        setVideo(true);


    }
    const ToggleCamera = () => {
        if (streaming) {

            navigator.mediaDevices.getUserMedia({ video: true, audio: false })
                .then(function (stream) {
                    videoElement.srcObject = stream;
                    videoElement.style.cssText = "-moz-transform: scale(-1, 1); \
          -webkit-transform: scale(-1, 1); -o-transform: scale(-1, 1); \
          transform: scale(-1, 1); filter: FlipH;"
                    videoElement.play().then(() => {


                        height = videoElement.videoHeight / (videoElement.videoWidth / width);

                        videoElement.setAttribute('width', width);
                        videoElement.setAttribute('height', height);


                    })
                }).catch(function (err) {
                    console.log("An error occurred: " + err);
                });
        }
        else {
            const stream = videoElement.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(function (track) {
                track.stop();
            });

            videoElement.srcObject = null;
        }

    }




    const setIFrame = () => {
        var type = password ? "host" : "member";
        var iframe = document.getElementById('editor'); /*editor url needs to be refreshed as it may have new audio added in the previous step*/
        if (iframe)
            iframe.src = "https://" + meetingURL + "?meeting_id=" + meetingId + "&user_type=" + type + "&access_code=" + accessCode
    }



    const returnVideoBtn = () => {
        if (!streaming) {
            return (
                <IconButton color="secondary" aria-label="delete" onClick={TurnCameraOn} >
                    <VideoOff />
                </IconButton>

            )
        }
        else {
            return (
                <IconButton color="secondary" aria-label="delete" onClick={TurnCameraOff}>
                    <VideoOn />
                </IconButton>)
        }
    }
    function toggleScreenShare() {

        if (isScreenSharedLocal) {
            handleScreenSharingEnded();

        }
        else {


            AgoraRTC.createScreenVideoTrack().then((track) => {
                var dbRef = firebase.database().ref('space_' + accessCode + '/' + meetingId + '/');
                dbRef.update({ active_screen_trackID: options.uid }).then(() => {
                    setScreenTrack(track);

                }).catch((err) => {
                    setOpen(true);
                    setErrorMessage("cannot connect to firebase");
                })
            })
        }

    }
    function setupScreenShare(track) {
        console.log("localtrack unpublished")

        setIsScreenSharedLocal(true);
        console.log(client.localTracks)
        publishTracks(track);


        track.on("track-ended", handleScreenSharingEnded)
    }
    async function handleScreenSharingEnded() {
        setIsScreenSharedLocal(false);

        var dbRef = firebase.database().ref('space_' + accessCode + '/' + meetingId + '/');

        await dbRef.update({ active_screen_trackID: "" });
        await client.unpublish(localTracks.videoTrack);
        AgoraRTC.createCameraVideoTrack().then((track) => {
            localTracks.videoTrack = track;
            publishTracks(track);

        })




    }
    async function publishTracks(track) {
        await client.publish(track);
        console.log("pubslished")
    }

    function getVideoThumbnailRemote(item) {
        if (item.uid !== options.uid) {
            return (
                <div style={{ height: "25%", width: "100%", position: "relative" }} key={item.uid} id={"player-wrapper" + item.uid}>
                    <div id={"player-" + item.uid} className={classes.videoThumbnail}>
                        {<div className={classes.cameraPlaceholderBG + " " + classes.videoThumbnail} > </div>

                        }

                    </div>

                    <p style={{ fontSize: "1.25rem", color: "#fff", position: "absolute", margin: "8px", bottom: "0", textTransform: "capitalize", width: "Calc(100% - 16px)" }} id={item.uid}>{item.name}</p>

                </div>
            )
        }

        return;
    }

    function getMobileVersion() {
        return (<Box display="flex" justifyContent="flex-start" margin="0 auto" flexDirection="column" height=" Calc(100VH - 86px);">

            <Box display="flex" flexDirection="column" justifyContent="space-around" >


                <Box position="relative" display="flex" height="330px" p={3} flexDirection="row" justifyContent="center" >
                    <Box zIndex="4" overflow="hidden"  >  <video id="video-mobile"  >Video stream not available.</video></Box>
                    <Box height="232px" width="310px" style={{
                        backgroundColor: "black", position: 'absolute', zIndex: 3,
                        display: 'flex', flexDirection: "column", justifyContent: "center"
                    }} >
                        <AccountCircleRounded htmlColor="white" style={{ height: "6rem", width: "6rem", margin: "0 auto" }} />
                        <p style={{ color: "#FFF", textAlign: 'center', position: 'relative', top: '-15px' }}>Camera Off</p>

                    </Box>

                    <Box style={{
                        backgroundColor: "#3A3939"
                    }} position="absolute" bottom="40px" zIndex="4" display="flex" flexDirection="row" >

                        <div className={audio ? classes.on : classes.off}>
                            <IconButton aria-label="delete" onClick={() => { setAudio(!audio) }} color="secondary" >
                                {audio ? <MicOn /> :
                                    <MicOff />}
                            </IconButton>
                        </div>
                        <div className={streaming ? classes.on : classes.off}>
                            {returnVideoBtn()}
                        </div>
                    </Box>


                </Box>

                {hostJoined ? <Box textAlign="center" color="primary.main" justifyContent="center" flexDirection="column" alignItems="center" display="flex">
                    <Box fontSize="2rem" mb={2}>Joining the meeting...</Box>


                </Box> : <Box textAlign="center" alignItems="center" color="primary.main" justifyContent="center" flexDirection="column" display="flex" >
                        {
                            step === 1 ? <Box textAlign="left">
                                <Box fontSize="1.25rem" fontWeight="bold">Join a Meeting  </Box>
                                <Box fontSize="0.75rem" fontWeight="bold" >Please enter your name  </Box>

                            </Box> : <Box textAlign="center" fontWeight="bold " >

                                    {/* <Box p={2} fontSize="34px" fontWeight="500" color="primary">Are you the Host? </Box> */}
                                    <Box fontSize="1.25rem">Please wait.</Box>
                                    <Box fontSize="1.25rem" >The meeting will begin soon</Box>
                                    <Box fontSize=".75rem" >If you are the <span style={{ color: "#ffaa00" }}>HOST</span>, please enter the password to </Box>
                                    <Box fontSize=".75rem" > start this meeting  </Box>
                                </Box>
                        }


                        <Box display="flex" flexDirection="column " width="300px" justifyContent="center"  >
                            {
                                step === 1 ? <TextField autoFocus id="outlined-name" label="Name" placeholder="Name" style={{ marginBottom: "16px" }} variant="outlined" name="userName" margin="normal" onKeyDown={(e) => {
                                    console.log("presesd - ")
                                    if (e.key === 'Enter' && name)
                                        checkMeetingStarted()
                                }} value={name} onChange={(e) => { setName(e.target.value) }} />
                                    :
                                    <TextField style={{ marginBottom: "16px" }} id="outlined-pass" label="Password (only for host)" placeholder="Password (only for Host)" type="password" name="password" variant="outlined" margin="normal" value={password} onKeyDown={(e) => {
                                        if (e.key === 'Enter' && password) {//host
                                            console.log("presesd - ")
                                            verifyPassCode();
                                        }
                                        else {//guest

                                        }
                                    }} onChange={(e) => { setPassword(e.target.value) }} />

                            }


                            <Box>
                                {step === 1 ?
                                    <Fragment>
                                        <Button disabled={isLoading || !name} style={{ width: "300px", height: "49px" }} size="large" color="secondary" classes={{ label: classes.textSecondary }} variant="contained" onClick={() => {
                                            checkMeetingStarted()
                                        }} >Next</Button>
                                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </Fragment>
                                    :
                                    <Button disabled={!password} style={{ width: "300px", height: "49px" }} size="large" color="secondary" classes={{ label: classes.textSecondary }} variant="contained" onClick={() => {
                                        if (password) {//host
                                            console.log(password);
                                            verifyPassCode();
                                        }
                                        else {//guest

                                        }
                                    }}  >Start</Button>}

                            </Box>

                        </Box>

                    </Box>}
            </Box>



        </Box >)
    }


    return (



        <Box height="100vh" className={meetingStarted ? classes.darkBG : ""}>
            {returnDialog()}
            {

                <Collapse in={errorOpen} className={classes.alert}>
                    <Alert severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errorMessage}
                    </Alert>
                </Collapse>


            }

            {!meetingStarted && <Box>
                <img style={{ height: '50px', padding: "16px" }} src={Logo} />
            </Box>
            }
            {!meetingStarted && matches && <Box display="flex" justifyContent="center" width="1140px" height="375px" margin="0 auto" flexDirection="column" height=" Calc(100% - 86px);">
                <Box display="flex" flexDirection="row" justifyContent="space-around" height="375px">


                    <Box position="relative" display="flex" height="375px" width="500px" p={3} flexDirection="row" justifyContent="center" >
                        <Box zIndex="4"  >  <video id="video"  >Video stream not available.</video></Box>
                        <Box height="375px" width="500px" style={{
                            backgroundColor: "black", position: 'absolute', zIndex: 3,
                            display: 'flex', flexDirection: "column", justifyContent: "center"
                        }} >
                            <AccountCircleRounded htmlColor="white" style={{ height: "6rem", width: "6rem", margin: "0 auto" }} />
                            <p style={{ color: "#FFF", textAlign: 'center' }}>Camera Off</p>

                        </Box>

                        <Box style={{
                            backgroundColor: "#3A3939"
                        }} position="absolute" bottom="40px" zIndex="4" display="flex" flexDirection="row" >

                            <div className={audio ? classes.on : classes.off}>
                                <IconButton aria-label="delete" onClick={() => { setAudio(!audio) }} color="secondary" >
                                    {audio ? <MicOn /> :
                                        <MicOff />}
                                </IconButton>
                            </div>
                            <div className={streaming ? classes.on : classes.off}>
                                {returnVideoBtn()}
                            </div>
                        </Box>


                    </Box>

                    {hostJoined ? <Box textAlign="center" color="primary.main" justifyContent="center" flexDirection="column" alignItems="center" display="flex">
                        <Box fontSize="2rem" mb={2}>Joining the meeting...</Box>


                    </Box> : <Box textAlign="center" color="primary.main" justifyContent="center" flexDirection="column" display="flex" width="430px">
                            {
                                step === 1 ? <Box textAlign="left">
                                    <Box fontSize="2.2rem" fontWeight="500">Join a Meeting  </Box>
                                    <Box fontSize="0.875rem" >Please enter your name  </Box>

                                </Box> : <Box textAlign="left" fontWeight="500 " >

                                        {/* <Box p={2} fontSize="34px" fontWeight="500" color="primary">Are you the Host? </Box> */}
                                        <Box fontSize="1.8rem" >Please wait.</Box>
                                        <Box fontSize="1.8rem" >The meeting will begin soon</Box>
                                        <Box fontSize=".875rem" >If you are the <span style={{ color: "#ffaa00" }}>HOST</span>, please enter the password to start this meeting  </Box>
                                    </Box>
                            }


                            <Box display="flex" flexDirection="column " width="300px" justifyContent="center"  >
                                {
                                    step === 1 ? <TextField autoFocus id="outlined-name" label="Name" placeholder="Name" style={{ marginBottom: "16px" }} variant="outlined" name="userName" margin="normal" onKeyDown={(e) => {
                                        console.log("presesd - ")
                                        if (e.key === 'Enter' && name)
                                            checkMeetingStarted()
                                    }} value={name} onChange={(e) => { setName(e.target.value) }} />
                                        :
                                        <TextField style={{ marginBottom: "16px" }} id="outlined-pass" label="Password (only for host)" placeholder="Password (only for Host)" type="password" name="password" variant="outlined" margin="normal" value={password} onKeyDown={(e) => {
                                            if (e.key === 'Enter' && password) {//host
                                                console.log("presesd - ")
                                                verifyPassCode();
                                            }
                                            else {//guest

                                            }
                                        }} onChange={(e) => { setPassword(e.target.value) }} />

                                }


                                <Box>
                                    {step === 1 ?
                                        <Fragment>
                                            <Button disabled={isLoading || !name} style={{ width: "300px", height: "49px" }} size="large" color="secondary" classes={{ label: classes.textSecondary }} variant="contained" onClick={() => {
                                                checkMeetingStarted()
                                            }} >Next</Button>
                                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </Fragment>
                                        :
                                        <Button disabled={!password} style={{ width: "300px", height: "49px" }} size="large" color="secondary" classes={{ label: classes.textSecondary }} variant="contained" onClick={() => {
                                            if (password) {//host
                                                console.log(password);
                                                verifyPassCode();
                                            }
                                            else {//guest

                                            }
                                        }}  >Start</Button>}

                                </Box>

                            </Box>

                        </Box>}
                </Box>



            </Box >
            }
            {!meetingStarted && !matches && getMobileVersion()}
            {meetingStarted && <Box display="flex" flexDirection="column" height="100%" >




                <Box display="flex" flexDirection="row" height="100%">
                    <Box width="80%">

                        <iframe allowFullScreen={true} mozAllowFullScreen={true} webkitAllowFullScreen={true} width="100%" height="100%" frameBorder="0px" id="editor"  ></iframe>
                        {
                            isScreenShared && <div id="screen-share" style={{
                                height: "Calc(100VH - 64px)",
                                position: "absolute",
                                width: "80VW",
                                top: 0,
                            }} >
                            </div>
                        }
                        {
                            isScreenSharedLocal && <div style={{
                                height: "Calc(100VH - 64px)",
                                position: "absolute",
                                width: "80VW",
                                top: 0,
                                background: "#292929",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }} >
                                <p
                                    style={{
                                        border: '1px solid #fff',
                                        borderRadius: '10px',
                                        width: '390px',
                                        textAlign: 'center',
                                        height: ' 163px',
                                        lineHeight: '163px',
                                        fontSize: '18px',
                                        color: '#fff'

                                    }}
                                >You are sharing your screen</p>
                                <Button style={{ width: "300px", height: "49px" }} size="large" color="secondary" classes={{ label: classes.textSecondary }} variant="contained" onClick={() => {
                                    toggleScreenShare();

                                }}  >Stop Sharing</Button>

                                <p
                                    style={{
                                        position: 'absolute',
                                        bottom: '0px',
                                        left: '0px',
                                        paddingLeft: '30px',
                                        color: '#fff'
                                    }}><AccountCircleRounded
                                        style={{
                                            position: 'relative',
                                            top: '5px',
                                            left: '-4px'
                                        }}
                                    ></AccountCircleRounded>
                                    {name} you are presenting</p>
                            </div>
                        }


                    </Box>
                    <Box display="flex" flexDirection="column " className={classes.presentationWindow} >
                        <Box height="calc( 20% - 1px)" width="100%" position="relative" >
                            {video ? <div id="local-player" className={classes.videoThumbnail} >

                            </div> : <div className={classes.cameraPlaceholderBG + " " + classes.videoThumbnail} > </div>

                            }

                            <p style={{ fontSize: "1.25rem", color: "#fff", position: "absolute", margin: "8px", bottom: "0", textTransform: "capitalize", width: "Calc(100% - 16px)" }}>{name}</p>
                        </Box>
                        <div id="remote-playerlist" style={{ height: "80%", width: "100%", overflow: "auto" }} >

                            {attendeesFromFbase && attendeesFromFbase.map(item => (
                                getVideoThumbnailRemote(item)
                            ))}

                        </div>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" flexDirection="row">
                    <IconButton aria-label="delete" className={classes.margin} onClick={() => { setAudio(!audio) }} style={{ color: "#fff" }}>
                        {audio ? <MicOn /> :
                            <MicOff />}
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => { setVideo(!video) }} style={{ color: "#fff" }} className={classes.margin}>
                        {video ? <VideoOn /> :
                            <VideoOff />}
                    </IconButton>
                    {!isScreenSharedLocal ? <IconButton aria-label="delete" onClick={toggleScreenShare} style={{ color: "#fff" }} className={classes.margin}>
                        <ScreenShare />
                    </IconButton> :
                        <IconButton aria-label="delete" onClick={toggleScreenShare} className={classes.margin} style={{ color: "#00FF90" }}>
                            <ScreenShare />
                        </IconButton>}
                    <IconButton aria-label="delete" onClick={() => {
                        setLeaveConfirmDialogOpen(true)
                    }} className={classes.margin} style={{ color: "red" }}>
                        <CallEndIcon />
                    </IconButton>
                </Box>

            </Box>

            }
        </Box >

    );
}